<template>
  <keep-alive>
    <div name="index">
      <div class="container" style="margin: 0 10px">
        <div class="source">
          {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
          <router-link :to="{ path: '/' }">{{ $t('index.index') }}</router-link>
          &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
          <router-link :to="{ name: 'xmlist' }">{{ $t('index.projectQuery') }}</router-link>
        </div>
        <div class="news">
          <div class="news-bg">{{ $t('index.projectQuery') }}</div>
          <div class="list">
            <input type="text" class="sfz"
              :placeholder="$t('public.keywordQuery')"
              v-model="searchXm"
            />
            <button
              class="button col button-fill button-large"
              v-on:click="searchClick"
            >
              {{ $t('public.queryBtn') }}
            </button>
          </div>
          <FlatList
            :list="xmNewsList"
            height="300px"
            :showLoadSpin="showLoadSpin"
            :isRefresh="isRefresh"
          >
            <div v-for="item in xmNewsList" :key="item.id">
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">
                    <router-link
                      :to="{ name: 'xmdetail', query: { id: item.id } }"
                    >
                      {{ item.title }}
                    </router-link>
                  </div>
                  <div class="item-after">{{ item.time.slice(5) }}</div>
                </div>
              </div>
            </div>
          </FlatList>
        </div>
        <div class="footer">
          <p v-if="todayView">
            {{ $t('footer.todayVisits') }}：{{ todayView }} &nbsp;&nbsp;{{ $t('footer.cumulativeVisits') }}：{{ totalView }}
          </p>
          <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
          <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
        </div>
      </div>
    </div>
  </keep-alive>
</template>
<script>
import BASE from "../utils/base";
import { calcScroll, listenScrollToBottom } from "../utils/util";
import FlatList from "../components/FlatList";

export default {
  name: "app",
  components: { FlatList },
  async activated() {
    this.pageNo = 0;
    let name = this.$route.query.name;
    if (name) {
      this.searchXm = name.indexOf("*") > -1 ? name.replaceAll("*", "") : name;
    } else {
      this.searchXm = "";
    }

    let res = await this.getList({ title: "*" + this.searchXm + "*" });
    let data = res.data;
    if (data.result) {
      this.xmNewsList = data.result.records;
      this.pageNo = data.result.current;
      this.pages = data.result.pages;
      this.pageSize = data.result.size;
      this.setFooter(res.data.result.records.length === this.pageSize);
    }
  },
  data() {
    return {
      result: [],
      active: 1,
      todayView: null,
      totalView: null,
      xmNewsList: [],
      id: null,
      pageNo: 1,
      pageSize: 10,
      searchXm: "",
      searchXmParams: "",
      pages: 0,
      showLoadSpin: 0,
      isRefresh: false,
      title: this.$route.query.name || "",
    };
  },
  mounted() {},
  methods: {
    async searchClick() {
      this.pageNo = 1;
      const res = await this.getList({ title: "*" + this.searchXm + "*" });
      let data = res.data;
      if (data.result) {
        this.xmNewsList = data.result.records;
        this.pageNo = data.result.current;
        this.pages = data.result.pages;
        this.pageSize = data.result.size;
        this.setFooter(res.data.result.records.length === this.pageSize);
      }
    },
    // async onRefresh() {
    //   this.pageNo = 1;
    //   this.isRefresh = true;
    //   let res = await this.getList({ title: "*" + this.searchXm + "*" });
    //   this.xmNewsList = res.data.result.records;
    //   this.setFooter(res.data.result.records.length === this.pageSize);
    //   this.isRefresh = false;
    // },
    setFooter(bool) {
      if (bool) {
        this.onLoad();
        this.showLoadSpin = 1;
      } else {
        this.showLoadSpin = 0;
      }
    },
    getList(param = {}) {
      // 项目列表
      return this.axios.get(
        BASE.genUrl("zxSuperviseProject/list", {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          ...param,
        })
      );
    },
    onLoad() {
      const that = this;
      listenScrollToBottom({
        ele: ".list-wrap",
        callBack: (ele) => {
          that.showLoadSpin = 1;
          return calcScroll({
            ele,
            callBack: async () => {
              that.pageNo += 1;
              if (that.pageNo > that.pages) {
                that.showLoadSpin = 2;
                return;
              }
              const res = await that.getList({
                title: "*" + this.searchXm + "*",
              });
              let data = res.data;
              if (data.result) {
                that.xmNewsList = that.xmNewsList.concat(data.result.records);
              }
            },
          });
        },
      });
    },
  },
  watch: {},
};
</script>
<style scoped>
a.disabled {
  color: #ccc;
}

.data-table-pagination {
  font-size: 14px;
  padding: 1rem;
}

.data-table-pagination a {
  padding: 0 1rem;
}

.detail h3 {
  color: #fff;
  font-size: 16px;
}

.detail h4 {
  color: #fff;
  font-size: 14px;
}

.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.source a {
  color: #fff;
}

.detail img {
  width: 100%;
}

.zclist li {
  text-align: left;
}

.zclist li a {
  color: #fff;
  font-size: 14px;
}

.item-content {
  padding: 0.5rem 0;
}

.item-inner {
  display: flex;
  flex-direction: row;
}

.item-title {
  font-size: 14px;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.item-after {
  width: 15%;
}

.tab {
  display: none;
}

.tab-active {
  display: block;
}

#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
}

#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
}

#tab3 .item,
#tab2 .item {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
}

#tab2 .item .l {
  width: 50%;
  display: block;
}

#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}

#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}

#tab2 .item .r div {
  padding: 0 0.2rem;
}

#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}

#tab2 .info-div,
#tab3 .info-div {
  width: 50%;
  margin-right: 0.1rem;
  margin-top: 20px;
}

#tab2 .info-title,
#tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}

#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}

#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}

#tab2 .item,
#tab3 .item {
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}

.list ul:before {
  background: none;
}

.list .zhengce-select {
  display: flex;
  flex-direction: row;
}

.list .zhengce-select-div {
  width: 50%;
}

.list .zhengce-select-div select {
  width: 95%;
  font-size: 12px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}

.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}

.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}

.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}

.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}

.container .nav-tabs {
  display: flex;
}

.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}

.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}

.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container .news button,
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.container .tabs .tab-active {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}

.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}
</style>
